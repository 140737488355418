import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper style={{
      textAlign: 'justify'
    }} mdxType="Wrapper">
  <h3>Unsere Social-Media-Auftritte</h3> <h4>Datenverarbeitung durch soziale Netzwerke</h4> <p>Wir unterhalten
  öffentlich zugängliche Profile in sozialen Netzwerken. Die im Einzelnen von uns genutzten sozialen Netzwerke finden
  Sie weiter unten.</p> <p>Soziale Netzwerke wie Facebook, Twitter etc. können Ihr Nutzerverhalten in der Regel
  umfassend analysieren, wenn Sie deren Website oder eine Website mit integrierten Social-Media-Inhalten (z. B.
  Like-Buttons oder Werbebannern) besuchen. Durch den Besuch unserer Social-Media-Präsenzen werden zahlreiche
  datenschutzrelevante Verarbeitungsvorgänge ausgelöst. Im Einzelnen:</p> <p>Wenn Sie in Ihrem Social-Media-Account
  eingeloggt sind und unsere Social-Media-Präsenz besuchen, kann der Betreiber des Social-Media-Portals diesen Besuch
  Ihrem Benutzerkonto zuordnen. Ihre personenbezogenen Daten können unter Umständen aber auch dann erfasst werden, wenn
  Sie nicht eingeloggt sind oder keinen Account beim jeweiligen Social-Media-Portal besitzen. Diese Datenerfassung
  erfolgt in diesem Fall beispielsweise über Cookies, die auf Ihrem Endgerät gespeichert werden oder durch Erfassung
  Ihrer IP-Adresse.</p> <p>Mit Hilfe der so erfassten Daten können die Betreiber der Social-Media-Portale Nutzerprofile
  erstellen, in denen Ihre Präferenzen und Interessen hinterlegt sind. Auf diese Weise kann Ihnen interessenbezogene
  Werbung in- und außerhalb der jeweiligen Social-Media-Präsenz angezeigt werden. Sofern Sie über einen Account beim
  jeweiligen sozialen Netzwerk verfügen, kann die interessenbezogene Werbung auf allen Geräten angezeigt werden, auf
  denen Sie eingeloggt sind oder eingeloggt waren.</p> <p>Bitte beachten Sie außerdem, dass wir nicht alle
  Verarbeitungsprozesse auf den Social-Media-Portalen nachvollziehen können. Je nach Anbieter können daher ggf. weitere
  Verarbeitungsvorgänge von den Betreibern der Social-Media-Portale durchgeführt werden. Details hierzu entnehmen Sie
  den Nutzungsbedingungen und Datenschutzbestimmungen der jeweiligen Social-Media-Portale.</p> <h4>Rechtsgrundlage</h4>
  <p>Unsere Social-Media-Auftritte sollen eine möglichst umfassende Präsenz im Internet gewährleisten. Hierbei handelt
    es sich um ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO. Die von den sozialen Netzwerken
    initiierten Analyseprozesse beruhen ggf. auf abweichenden Rechtsgrundlagen, die von den Betreibern der sozialen
    Netzwerke anzugeben sind (z. B. Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO).</p> <h4>Verantwortlicher und
  Geltendmachung von Rechten</h4> <p>Wenn Sie einen unserer Social-Media-Auftritte (z. B. Facebook) besuchen, sind wir
  gemeinsam mit dem Betreiber der Social-Media-Plattform für die bei diesem Besuch ausgelösten
  Datenverarbeitungsvorgänge verantwortlich. Sie können Ihre Rechte (Auskunft, Berichtigung, Löschung, Einschränkung der
  Verarbeitung, Datenübertragbarkeit und Beschwerde) grundsätzlich sowohl ggü. uns als auch ggü. dem Betreiber des
  jeweiligen Social-Media-Portals (z. B. ggü. Facebook) geltend machen.</p> <p>Bitte beachten Sie, dass wir trotz der
  gemeinsamen Verantwortlichkeit mit den Social-Media-Portal-Betreibern nicht vollumfänglich Einfluss auf die
  Datenverarbeitungsvorgänge der Social-Media-Portale haben. Unsere Möglichkeiten richten sich maßgeblich nach der
  Unternehmenspolitik des jeweiligen Anbieters.</p> <h4>Speicherdauer</h4> <p>Die unmittelbar von uns über die
  Social-Media-Präsenz erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns zur Löschung auffordern,
  Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies
  verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende gesetzliche Bestimmungen – insb. Aufbewahrungsfristen –
  bleiben unberührt.</p> <p>Auf die Speicherdauer Ihrer Daten, die von den Betreibern der sozialen Netzwerke zu eigenen
  Zwecken gespeichert werden, haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den
  Betreibern der sozialen Netzwerke (z. B. in deren Datenschutzerklärung, siehe unten).</p> <h4>Soziale Netzwerke im
  Einzelnen</h4>
  <h5>Facebook</h5> <p>Wir verfügen über ein Profil bei Facebook. Anbieter dieses Dienstes ist die Facebook Ireland
  Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von
  Facebook auch in die USA und in andere Drittländer übertragen.</p>  <p>Wir haben mit Facebook eine Vereinbarung über
  gemeinsame Verarbeitung (Controller Addendum) geschlossen. In dieser Vereinbarung wird festgelegt, für welche
  Datenverarbeitungsvorgänge wir bzw. Facebook verantwortlich ist, wenn Sie unsere Facebook-Page besuchen. Diese
  Vereinbarung können Sie unter folgendem Link einsehen: <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noopener noreferrer">https://www.facebook.com/legal/terms/page_controller_addendum</a>.</p>
  <p><strong>Sie können Ihre Werbeeinstellungen selbstständig in Ihrem Nutzer-Account anpassen. Klicken Sie hierzu auf
    folgenden Link und loggen Sie sich ein:</strong> <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">https://www.facebook.com/settings?tab=ads</a>.
  </p> <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
  finden Sie hier: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a> und <a href="https://de-de.facebook.com/help/566994660333381" target="_blank" rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a>.</p> <p>Details entnehmen Sie der
  Datenschutzerklärung von Facebook: <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/about/privacy/</a>.</p>
  <h5>Instagram</h5> <p>Wir verfügen über ein Profil bei Instagram. Anbieter dieses Dienstes ist die Facebook Ireland
  Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.</p> <p>Die Datenübertragung in die USA wird auf
  die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>, <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener noreferrer">https://help.instagram.com/519522125107875</a> und <a href="https://de-de.facebook.com/help/566994660333381" target="_blank" rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a>.</p> <p>Details zu deren Umgang mit
  Ihren personenbezogenen Daten entnehmen Sie der Datenschutzerklärung von Instagram: <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener noreferrer">https://help.instagram.com/519522125107875</a>.</p>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      